import React, { useEffect, useState } from 'react';
import { RadixDappToolkit, DataRequestBuilder, OneTimeDataRequestBuilder } from '@radixdlt/radix-dapp-toolkit';

import './App.css';

import RadixConnector from './pages/radixconnector/RadixConnector';

function App() {
  return (
    <div className="appContainer">
      <RadixConnector />
    </div>
  );
}

export default App;