import React, { useEffect, useState } from 'react';
import { RadixDappToolkit, RadixNetwork, createLogger, OneTimeDataRequestBuilder, DataRequestBuilder, sendOneTimeRequest } from '@radixdlt/radix-dapp-toolkit';

import RunsOnRadixImg from "../../assets/runs_on_radix.png";
import InfiniteLogo from "../../assets/infinite_logo_no_background.png";

import Logo1 from "../../assets/1.gif";
import Logo2 from "../../assets/2.gif";
import Logo3 from "../../assets/3.gif";
import Logo4 from "../../assets/4.gif";
import Logo5 from "../../assets/5.gif";
import Logo6 from "../../assets/6.gif";
import Logo7 from "../../assets/7.gif";

import './radixconnector.css';

const RadixConnector = () => {;
  const [accountAddress, setAccountAddress] = useState();
  const [rdt, setRdt] = useState();
  const [ownedNfts, setOwnedNfts] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [idArray, setIdArray] = useState([]);
  const [receiver, setReceiver] = useState('');
  const [nftToSend, setNftToSend] = useState('');
  const [jsonData, setJsonData] = useState('');
  
  useEffect(() => {
    const radixDappToolkit = RadixDappToolkit({
      dAppDefinitionAddress:
        'account_rdx16xh98mpg4pp35zgd7j9rejsk3n525mle85nc8aq9f6h5zk8kmkfk74',
      networkId: 1,
      applicationName: 'Infinite Dimensions dApp',
      applicationVersion: '1.0.0',
    })

    const walletRequest = radixDappToolkit.walletApi.sendRequest()

    const subscribe = radixDappToolkit.walletApi.walletData$.subscribe((walletData) => {
      if (walletData.accounts.length) {
        const accountData = walletData.accounts[0].address
        setAccountAddress(accountData)
        async function fetchData() {
          await radixDappToolkit.walletApi.sendOneTimeRequest(
            OneTimeDataRequestBuilder.accounts().exactly(1),
          ).map((walletData) => {
            setIsLoggedIn(true)
            getWalletInfo(walletData.accounts[0].address)
          })
        }
        fetchData()
      } else {
        // inital state or user has logged out
      }  
    })
    setRdt(radixDappToolkit)
  }, [])
  
  async function instantiateInfinite() {
    try {
      console.log("instantiate");

      const result = await rdt.walletApi.sendTransaction({
          transactionManifest: `
            CALL_FUNCTION
                Address("package_tdx_2_1p4s7s8ymvf4sr5mzky553k3l334nuw70mektrf4wnygkeuzx6r7ej4")
                "InfiniteNft"
                "instantiate_infinite"
            ;
            CALL_METHOD
                Address("${accountAddress}")
                "try_deposit_batch_or_refund"
                Expression("ENTIRE_WORKTOP")
                Enum<0u8>()
            ;
            `,
          version: 1,
        })

        const transactionIntentHash = result.value.transactionIntentHash

        console.log(transactionIntentHash)
        console.log(result.value)

        
        let committedReceipt = await rdt.gatewayApi.transaction.getCommittedDetails(transactionIntentHash)
        console.log(committedReceipt);

        setIdArray(committedReceipt.transaction.receipt.events[1].data.fields[0].elements)
        console.log("id array: " + idArray);
    } catch (err) {
      console.log(err)
    }
  }

  async function buyAvatar() {
    try {
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: `
          CALL_METHOD
              Address("component_tdx_2_1cph7qzlqukw8vxyn00q39prhgdytuvk8l3ymhnxwx5yyganh80rqud")
              "buy_avatar"
          ;
          CALL_METHOD
              Address("${accountAddress}")
              "try_deposit_batch_or_refund"
              Expression("ENTIRE_WORKTOP")
              Enum<0u8>()
          ;
          `,
        version: 1,
      })

      console.log(result.value)

      console.log("buying avatar");
      const transactionIntentHash = result.value.transactionIntentHash

      let committedReceipt = await rdt.gatewayApi.transaction.getCommittedDetails(transactionIntentHash)
      console.log(committedReceipt);

      let newNft = committedReceipt.transaction.receipt.events[1].data.fields[0].elements[0].value;

      setOwnedNfts(newNft)
      setIdArray(committedReceipt.transaction.receipt.events[1].data.fields[0].elements)
      console.log("owned NFT's: " + ownedNfts);
      console.log("id array: " + idArray);
    } catch (err) {
      console.log(err)
    }
  }

  async function sendAvatar() { 
    try {
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: `
          CALL_METHOD
              Address("${accountAddress}")
              "withdraw_non_fungibles"
              Address("resource_tdx_2_1ng73rq6ffxq66fxtld4g63g2uvnpvze22gv8qp4squ3t70c4yq7c6e")
              Array<NonFungibleLocalId>(
                  NonFungibleLocalId("${nftToSend}")
              )
          ;
          CALL_METHOD
              Address("${receiver}")
              "try_deposit_batch_or_refund"
              Expression("ENTIRE_WORKTOP")
              Enum<0u8>()
          ;
          `,
        version: 1,
      })

      console.log(result.value)

      console.log("sending avatar");
    } catch (err) {

    }
  }

  async function getWalletInfo(accountData) {
    try {
      await fetch("https://mainnet.radixdlt.com/state/entity/details", {
        method: "POST",
        body: JSON.stringify({
          addresses: [
            accountData
          ],
          aggregation_level: "Vault",
          opt_ins: {
            ancestor_identities: true,
            component_royalty_vault_balance: true,
            package_royalty_vault_balance: true,
            non_fungible_include_nfids: true,
            explicit_metadata: [
              "name",
              "description"
            ]
          }
        }),
        headers: {
            'Content-type': 'application/json'
        },
      })
        .then(res => res.json())
        .then(sleep(100))
        .then(json => {
          console.log(json)

          let dimensionsTokenVault = "resource_rdx1nfqdp8a779pm29d4aqerynfp6vcp0pua4433e44uc0azjlmy6hxmzl"

          for (let i = 0; i < json.items[0].non_fungible_resources.items.length; i++) {
            if (json.items[0].non_fungible_resources.items[i].resource_address === dimensionsTokenVault) {              
              let arrayData = json.items[0].non_fungible_resources.items[i].vaults.items[0].items // <--- working with array items being set as #1#, #2#, etc

              setIdArray(arrayData)

              async function setTheWalletData() {
                try {
                  //await fetch("https://nodejs-example-express-rds.eba-kcjkiuim.us-east-1.elasticbeanstalk.com/set-data", {
                  //await fetch("https://oracle.infiniteoracles.com/set-data", {
                  //await fetch(`http://localhost:3001/set-data/${accountAddress}`, {
                  await fetch(`https://nodejs-example-express-rds.eba-kcjkiuim.us-east-1.elasticbeanstalk.com/set-data/${accountAddress}`, {
                    method: "POST",
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({ 
                      data: arrayData
                              .toString()
                              .replaceAll("[", "")
                              .replaceAll("]", "")
                              .replaceAll("#", "")
                              .replaceAll("\"", ""),
                      accountAddress: accountData,
                    }),
                    headers: 
                    {
                        "Content-Type": "application/json"
                    }
                  })
                    .then((result) => result.json())
                    .then((info) => { 
                      setIdArray(arrayData)
                      console.log("post request")
                    })    
                } catch(err) {
                  console.log(err)
                }
              }

              setTheWalletData()
              
            }
            
          }
        })
    } catch (err) {
      console.log(err)
    }
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleChange = (event) => {
    setReceiver(event.target.value);
    console.log("receive: " + receiver)
  };

  const nftIdToSend = (event) => {
    setNftToSend(event.target.value)
    console.log("nft to send: " + nftToSend)
  }
  
  return (
    <>
      <span className="walletContainer">
        <img className="runsOnRadixContainer" src={RunsOnRadixImg} height="25px" width="150px"/>
        <img className="logoContainer" src={InfiniteLogo} height="100px" width="400px"/>
        <span className="rdtButton">
          <radix-connect-button />
        </span>
        {/*
          <span>
          <button className="buttonStyles" onClick={instantiateInfinite}>instantiate</button>
          <button className="buttonStyles" onClick={buyAvatar}>buy</button>
          </span>
        */}
        {
          !isLoggedIn ? <p className="assetsHeader">Please verify through the Radix wallet app and select "Update data sharing" to continue</p> :              
          <>
            <span className="sendAssetContainer">
              <span className="sendAssetsInputContainer">
                <span className="sendFunctionContainer">
                  <p className='receiverContainer'>Enter account address to send to:</p>
                  <input
                      type="text"
                      onChange={handleChange}
                      className='inputContainer'
                  />
                </span>
                <span className="sendFunctionContainer">
                  <p className='assetToSendContainer'>Enter NFT ID to send **Ex: #1#**:</p>
                  <input
                      type="text"
                      onChange={nftIdToSend}
                      className='inputContainer'
                  />
                </span>
                <button className="buttonStyles" onClick={sendAvatar}>send</button>
              </span>
            </span>
            <section className="assetsContainer">
              <h1 className="yourAssetHeaderContainer">Your Assets:</h1>
              <span className="nftCardStyleContainer">
                {
                  idArray?.map((el, ind) => {
                    let name = undefined;
                    let collectionType = undefined;
                    let nftLogo =  undefined;

                    switch (el) {
                      case "#1#":
                        name = "OCI Cat";
                        collectionType = "OCI Cat";
                        nftLogo = Logo1;
                        break;
                      case "#2#":
                        name = "DogeCube Pup";
                        collectionType = "DogeCube";
                        nftLogo = Logo2;
                        break;
                      case "#3#":
                        name = "Gnome Society";
                        collectionType = "Gnome Society";
                        nftLogo = Logo3;
                        break;
                      case "#4#":
                        name = "Fractals Sphere";
                        collectionType = "Radical Fractals";
                        nftLogo = Logo4;
                        break;
                      case "#5#":
                        name = "Casual Penguin";
                        collectionType = "Radical Penguins";
                        nftLogo = Logo5;
                        break;
                      case "#6#":
                        name = "Smart Penguin";
                        collectionType = "Radical Penguins";
                        nftLogo = Logo6;
                        break;
                      case "#7#":
                        name = "Radical Robo";
                        collectionType = "Radical Robots";
                        nftLogo = Logo7;
                        break;
                      default:
                        break;
                    }

                    return(
                      <div key={ind}>
                        <div className='nftCardContainer'>
                          <img className='nftLogoContainer' src={nftLogo} height="60%" width="100%"></img>
                          <p className='nftNameContainer'>Name: {name}</p>
                          <p className='nftCollectionContainer'>Collection: {collectionType}</p>
                        </div>
                      </div>
                    );
                  })
                }
              </span>
            </section>
          </>
        }
      </span>
    </>
  );
}

export default RadixConnector;